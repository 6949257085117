import React from 'react';

import PhotoCardGrid from '../../components/PhotoCardGrid';

const MediaPage = () => {
    return (
        <PhotoCardGrid title='Trabajos Fotografia'></PhotoCardGrid>
    );
}

export default MediaPage;
