import React from 'react';

import ContactForm from '../../components/ContactForm'

import '../../styles/HomePage.scss'


const ContactPage = () => {
    return (
        <ContactForm ></ContactForm>
    );
}

export default ContactPage;
