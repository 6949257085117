import React from 'react';

import VideoCardGrid from '../../components/VideoCardGrid';

const MediaPage = () => {
    return (
        <VideoCardGrid></VideoCardGrid>
    );
}

export default MediaPage;
